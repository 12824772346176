import ApiService, { HTTPMethod } from './api.core'
import { buildQueryParamsUrl, KPretty } from '../services/utils'
import { Transformer } from '../models/Transformer'

class CoveragesApi {
    printVehicleCoverages(vehicleCoveragePrices) {
        let totalPrice = 0
        for (let vehIndex in vehicleCoveragePrices) {
            let veh = vehicleCoveragePrices[vehIndex]
            console.log('\tVehicle', vehIndex)
            for (let covKey in veh.coveragePrices) {
                let cov = veh.coveragePrices[covKey]
                console.log(
                    '\t\tCoverage: ',
                    covKey,
                    'Annual Price: ',
                    cov.annualPrice
                )
                totalPrice += cov.annualPrice
                if (cov.factorNameToValue) {
                    let factors = ''
                    for (let factorKey in cov.factorNameToValue) {
                        factors +=
                            factorKey +
                            ': ' +
                            cov.factorNameToValue[factorKey] +
                            ', '
                    }
                    console.log('\t\tFactors: ', factors)
                }
            }
        }
        console.log('\tTotal annual cost: ', totalPrice)
    }

    async calcMidtermPrice(offerId, insuranceId) {
        let res = await ApiService.fetch(
            'coverages/calcMidtermPrice',
            HTTPMethod.POST,
            {
                offer_id: offerId,
                insurance_id: insuranceId,
            }
        )
        return res
    }

    async loadPlans(duration) {
        duration = duration ? duration : 'Month'
        let res = await ApiService.fetch(
            'coverages/plans?duration=' + duration,
            HTTPMethod.GET
        )
        if (res.ok) {
            res.parsedData = Transformer.initializeFromDataDeep({}, res.data)
            for (let planIndex in res.parsedData.plans) {
                let plan = res.parsedData.plans[planIndex]
                console.log(plan.label)
                this.printVehicleCoverages(plan.vehicleCoveragePrices)
            }
        }
        return res
    }

    async loadOptions() {
        let res = await ApiService.fetch('coverages/options', HTTPMethod.GET)
        if (res.ok) {
            Object.keys(res.data).forEach((key) => {
                res.data[key].map((item) => {
                    if (item.breakdown) {
                        const liabilityBreakdownOrder = [
                            'Per Passenger',
                            'Per Accident',
                            'Property Damage',
                        ]
                        item.breakdown
                            .sort((a, b) =>
                                liabilityBreakdownOrder.indexOf(a.label) <
                                liabilityBreakdownOrder.indexOf(b.label)
                                    ? -1
                                    : 1
                            )
                            .map((k) => {
                                if (Number.isNaN(Number.parseInt(k.sublabel)))
                                    return k
                                else {
                                    k.sublabel = KPretty(k.sublabel)
                                    return k
                                }
                            })
                    }
                    if (item.subtitle) {
                        if (Number.isNaN(Number.parseInt(item.subtitle))) {
                            if (item.subtitle == 'true')
                                item.subtitle = 'Included'
                            if (item.subtitle == 'false')
                                item.subtitle = 'Not Included'
                        } else {
                            item.subtitle = KPretty(item.subtitle)
                        }
                    }

                    return item
                })
            })
        }
        return res
    }

    async bindMidtermOffer(offerId, insuranceId) {
        return await ApiService.fetch(
            'coverages/midtermBind',
            HTTPMethod.POST,
            {
                offer_id: offerId,
                insurance_id: insuranceId,
            }
        )
    }
    async requestOffer(configuration) {
        let res = await ApiService.fetch(
            'coverages/offer',
            HTTPMethod.POST,
            configuration
        )
        if (res.ok) {
            res.data = Transformer.initializeFromDataDeep({}, res.data)
            this.printVehicleCoverages(res.data.vehicleCoveragePrices)
        }
        return res
    }
    async checkCoverageValidity(limit, duration, liabilityLimit, price) {
        return await ApiService.fetch('coverages/checkAircraftDamageCoverageValidity', HTTPMethod.POST,{
            'insurance_duration':duration,
            'limit':limit,
            'liability_limit': liabilityLimit,
            'price': price
        })
    }
    // async checkProfileValidity() {
    //     return await ApiService.fetch('coverages/checkValidity', HTTPMethod.GET)
    // }


    async purchaseOffer(offerId, autoRenew) {
        let res = await ApiService.fetch('coverages/bind', HTTPMethod.POST, {
            offer_id: offerId,
            auto_renew: autoRenew,
        })
        return res
    }

    async hackSavePlan(offerId) {
        let res = await ApiService.fetch(
            `coverages/selectPlan?planId=${offerId}`,
            HTTPMethod.POST,
            {
                plan_id: offerId,
            }
        )
        return res
    }
}

export default new CoveragesApi()
