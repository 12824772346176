import ApiService, { HTTPMethod } from "./api.core";

export class AnalyticsApi {
    static async utmEvent() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());    
        await ApiService.fetch("analytics/utm_event", HTTPMethod.POST, {
            type: "UtmUserCreated",
            payload: {
                utm_source: params?.utm_source,
                utm_medium: params?.utm_medium,
                utm_campaign: params?.utm_campaign
            }
        })
    }
}